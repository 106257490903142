import { createRoot } from "react-dom/client";
import { DataBrowserRouter, Route } from "react-router-dom";
import { Application, loader as postsLoader } from "./Application.js";
import { Post, loader as postLoader } from "./Post.js";
import { Splash } from "./Splash.js";

createRoot(document.querySelector("main")).render(
  <DataBrowserRouter fallbackElement={<Splash />}>
    <Route path="/" element={<Application />} loader={postsLoader}>
      <Route path="posts/:id" element={<Post />} loader={postLoader} />
    </Route>
  </DataBrowserRouter>,
);

navigator.serviceWorker.register(new URL("service-worker.js", import.meta.url), { type: "module" });
