import { Radio } from "react-feather";

export function Splash() {
  return (
    <div className="splash">
      <Radio size={48} />
      <div className="splash-overlay">
        <Radio size={48} className="splash-animation" />
      </div>
    </div>
  );
}
