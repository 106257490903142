export async function topstories(n) {
  let ids = await json("https://hacker-news.firebaseio.com/v0/topstories.json");
  return Promise.all(ids.slice(0, n).map((id) => item(id)));
}

export async function item(id) {
  let post = await json(`https://hacker-news.firebaseio.com/v0/item/${id}.json`);
  return post;
}

async function json(url, options) {
  let response = await fetch(url, options);
  if (response.status >= 400 && response.status < 500) throw await response.json();
  else if (!response.ok) throw new Error(response.statusText);
  return response.json();
}
