import { Outlet, Link, useLoaderData } from "react-router-dom";
import { MessageCircle, Activity, Link as LinkIcon, User } from "react-feather";
import { topstories } from "./HN.js";

export async function loader() {
  return topstories(100);
}

export function Application() {
  let posts = useLoaderData();
  return (
    <div className="vstack posts">
      {posts.map((post) => (
        <div key={post.id} className="post">
          <div className="post-origin">
            <span>
              <User size={12} />
              {post.by}
            </span>
            {post.url != null ? (
              <span>
                <LinkIcon size={12} />
                {new URL(post.url).hostname}
              </span>
            ) : null}
          </div>
          {post.url != null ? (
            <a
              href={post.url}
              className="post-title post-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {post.title}
            </a>
          ) : (
            <Link to={`/posts/${post.id}`} className="post-title">
              {post.title}
            </Link>
          )}
          <span>
            <Activity size={18} />
            <span>{post.score}</span>
          </span>

          <Link to={`posts/${post.id}`}>
            <MessageCircle size={18} />
            <span>{post.descendants ?? 0}</span>
          </Link>
        </div>
      ))}
      <Outlet />
    </div>
  );
}
