import { Link, useLoaderData } from "react-router-dom";
import { User, ExternalLink, Minimize2, MessageCircle } from "react-feather";
import { item } from "./HN.js";

export async function loader(match) {
  let post = await item(match.params.id);
  let kids = await Promise.all(post.kids?.map((id) => item(id)) ?? []);
  return { post, kids };
}

export function Post() {
  let { post, kids } = useLoaderData();
  return (
    <section className="post-page">
      <div className="post-page-content">
        <h2>{post.title}</h2>
        {post.url != null ? (
          <p style={{ overflow: "auto", whiteSpace: "nowrap" }}>
            <a
              href={post.url}
              className="post-title post-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ExternalLink size={16} className="post-icon" />
              {post.url}
            </a>
          </p>
        ) : null}
        {post.type === "comment" ? (
          <p>
            <User size={16} /> <span>{post.by}</span>
          </p>
        ) : null}
        <p dangerouslySetInnerHTML={{ __html: post.text }} />
        <section className="vstack">
          {kids.map((kid) => (
            <div key={kid.id}>
              <p className="comment-origin">
                <span>
                  <User size={16} />
                  <span>{kid.by}</span>
                </span>
                {kid.kids != null ? (
                  <Link to={`/posts/${kid.id}`}>
                    <MessageCircle size={16} />
                    <span>{kid.kids.length}</span>
                  </Link>
                ) : null}
              </p>
              <p dangerouslySetInnerHTML={{ __html: kid.text }} className="post-embed" />
            </div>
          ))}
        </section>
      </div>
    </section>
  );
}
